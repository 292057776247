import { reset } from './reset';
import { grid } from './grid';
import { createGlobalStyle } from 'styled-components';
import { mediaQuery } from './mixins';

export default createGlobalStyle`

  @font-face {
    font-family: 'Raleway', sans-serif;
    src: url("../Fonts/Raleway-Regular.ttf");
    src: url("../Fonts/Raleway-Medium.ttf");
    src: url("../Fonts/Raleway-SemiBold.ttf");
    src: url("../Fonts/Raleway-Bold.ttf");
  }

  #gatsby-focus-wrapper {
    background-color: #0E3233;
  }

  ${reset}
  ${grid}
  
  p {
    letter-spacing: .48px;

    ${mediaQuery.desktop`
      letter-spacing:  inherit;
    `}
  }


`;
