import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.div`
  padding-bottom: 10px;
  border-bottom: 2px solid #0002;

  display: flex;
  flex-direction: column;

  margin-top: 30px;

  ${mediaQuery.desktop`
    margin-top: 0px;
  `}

  label {
    color: ${({ theme }) => theme.font.primary};
    font-weight: 200;
    font-size: 14px;
    border-bottom: 5px;
    margin-bottom: 5px;
    letter-spacing: 0.26px;

    margin-top: 15px;

    ${mediaQuery.desktop`
      margin-top: 0px;
    `}
  }

  textarea {
    height: 23px;
    font-size: 18px;
    border: none;
    font-weight: 600;
    font-family: 'Raleway', sans-serif;
    outline: none;

    ::placeholder {
      color: ${({ theme }) => theme.font.second};
      letter-spacing: 0px;
      font-size: 16px;
    }
  }
`;
