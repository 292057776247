import React from "react";

import { Container } from "./styles";

import { titleSectionProps } from "./interface";

const TitleSection = ({
  title,
  text,
  text2,
  className,
  children,
}: titleSectionProps) => {
  return (
    <Container className={`container ${className}`} text={text}>
      <article className="title-section">
        <div className="rectangle"></div>
        <h2>{title}</h2>
        {text && <p>{text}</p>}
        {text2 && <p>{text2}</p>}
        {children}
      </article>
    </Container >
  );
};

export default TitleSection;
