import React from "react";

import GlobalStyle from "../../assets/styles/global";
import { GatsbySeo } from "gatsby-plugin-next-seo";

import Header from "../Header";
import Footer from "../Footer";

import { ILayoutProps } from "./interface";

const Layout = ({ children }: ILayoutProps) => {
  return (
    <>
      <GatsbySeo
        title="Renta Investimentos"
        description="Atuação estratégica na gestão de Fundos de Investimento em Direitos Creditórios (FIDC)."
      />
      <GlobalStyle />
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Layout;