import styled from "styled-components";
import { mediaQuery } from "../../assets/styles/mixins";

export const Container = styled.section`
  .btn__content {
    width: 90%;
    margin-left: auto;
    display: none;

    ${mediaQuery.desktop`
      display: block
    `}
  }
  .slider__headers {
    margin-bottom: 50px;
    display: flex;
    justify-content: center;

    .slider__headersBtn {
      margin-right: 16px;
      font-weight: bold;
    }
  }

  .scroll-row {
    width: 100%;
    margin-left: 0;
    overflow-x: scroll;
    scroll-behavior: smooth;

    ${mediaQuery.desktop`
      margin-bottom: 40px;
    `}

    ::-webkit-scrollbar {
      width: 1rem;
    }

    ::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: transparent;
      outline: 2px solid slategrey;
    }

    ::-webkit-scrollbar {
        height: 0px;
        width: 0px;
        border: none;
      }

  }

  .scrolable {
    display: flex;
    padding: 10px 0;
    width: ${props => props.size}px;

    ${mediaQuery.desktop`
    `}
  }

  .slider__btn {
    font-size: 20px;
    font-weight: 600;
    background-color: transparent;
    border: none;
    margin-right: 20px;
    transition: 0.3s;

    svg .a {
      fill: none;
    }

    svg .b {
      fill: ${(props) => (props.arrowColor ? props.arrowColor : "#fff")};
    }
  }

  .rollback {
    transform: rotateY(180deg);
  }

  .opacity {
    opacity: 0.3;
  }
`;
