import React, { useEffect, useState } from "react";

import { Container } from "./styles";

import JumbotronSmaller from "../Jumbotron-smaller";
import Button from "../Button";

import { SliderProps } from "./interface";

const Slider = ({
  classContainer,
  classItem,
  activeBtnClass,
  arrowColor,
  items,
  headers,
  id,
  margin,
}: SliderProps) => {
  const [contentCards, setContentCards] = useState(items[0].content);
  const [active, setActive] = useState(0);
  const [size, setSize] = useState(0);
  const [scrollMax, setScrollMax] = useState(0);
  const [arrowLeft, setArrowLeft] = useState(false);
  const [arrowRight, setArrowRight] = useState(true);

  const handleScroll = (position, id) => {
    let pos = parseInt(position + 5);
    let scroll = document.querySelectorAll(".scroll-row")[id].scrollLeft + pos;

    if (scroll < 0) scroll = 0;
    else if (scroll > scrollMax) scroll = scrollMax;

    document.querySelectorAll(".scroll-row")[id].scroll({
      left: scroll,
    });

    return scroll;
  };

  useEffect(() => {
    const card = document.querySelector(".itemCard").clientWidth + 15;
    setSize(card * contentCards.length + margin);
    setScrollMax(document.querySelectorAll(".scroll-row")[id].scrollLeftMax + 155);
  }, []);

  function handleRigth() {
    setArrowLeft(true);

    let position = (scrollMax / contentCards.length) * 2;

    const scrollLeft = handleScroll(position, id);

    if (scrollLeft === scrollMax) setArrowRight(false);
  }

  function handleLeft() {
    setArrowRight(true);

    let position = -(scrollMax / contentCards.length) * 2;

    const scrollLeft = handleScroll(position, id);

    if (scrollLeft === 0) setArrowLeft(false);
  }

  return (
    <Container arrowColor={arrowColor} size={size}>
      {headers && (
        <div className="slider__headers">
          {headers.map((header, i) => (
            <Button
              className={`slider__headersBtn ${active === i ? activeBtnClass : ""
                }`}
              key={header.btnText}
              type="button"
              hiddenIcon={false}
            >
              {header.btnText}

            </Button>
          ))}
        </div>
      )}
      <div className={`scroll-row ${classContainer}`}>
        <div className="scrolable">
          {contentCards.map((item) => (
            <JumbotronSmaller
              className={`itemCard ${classItem}`}
              key={item.id}
              number={item.number}
              title={item.title}
              text={item.text}
            />
          ))}
        </div>
      </div>
      <div className="btn__content">
        <button
          className={`slider__btn rollback ${arrowLeft ? '' : 'opacity'}`}
          onClick={() => handleLeft()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
          >
            <g transform="translate(0 50)">
              <rect
                className="a"
                width="30"
                height="30"
                transform="translate(0 -50)"
              />
              <path
                className="b"
                d="M14.053,4,12.294,5.759,19.331,12.8H4V15.31H19.331l-7.037,7.037,1.759,1.759L24.107,14.053Z"
                transform="translate(0.733 -49.267)"
              />
            </g>
          </svg>
        </button>
        <button
          className={`slider__btn ${arrowRight ? '' : 'opacity'}`}
          onClick={() => handleRigth()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
          >
            <g transform="translate(0 50)">
              <rect
                className="a"
                width="30"
                height="30"
                transform="translate(0 -50)"
              />
              <path
                className="b"
                d="M14.053,4,12.294,5.759,19.331,12.8H4V15.31H19.331l-7.037,7.037,1.759,1.759L24.107,14.053Z"
                transform="translate(0.733 -49.267)"
              />
            </g>
          </svg>
        </button>
      </div>
    </Container>
  );
};

export default Slider;
