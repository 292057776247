import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.div`
  position: sticky;
  width: 100%;
  padding: 30px;
  height: 100px;
  justify-content: space-between;

  bottom: ${({ state }) => (state ? '0px!important' : 'calc(-100vh + 80px);')};
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.font.third};
  transition: 0.8s;

  .justifyment-row {
    align-items: center;
  }

  .msg__image-container {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.font.white};
    margin-right: 0;
    margin-bottom: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    ${mediaQuery.desktop`
        margin-right: 25px;
        margin-bottom: 0px;
    `}
  }

  .msg__content {
    display: flex;
    max-width: 80%;

    flex-direction: column;
    color: ${({ theme }) => theme.font.white};

    ${mediaQuery.desktop`
      max-width: 80%;
    `}

    .msg__title {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .msg__p {
      text-align: ${({ icon }) => (icon ? 'left' : 'none')};
      font-size: 14px;
      font-weight: 400;
      opacity: 0.8;
      letter-spacing: unset;
    }
  }

  .msg__button {
    display: none;
    
    ${mediaQuery.desktop`
      display: flex;
      width: 64px;
      height: 39px;
      font-size: 16px;
      font-weight: 600;
      background-color: ${({ theme }) => theme.font.third};
      border: 1px solid #fff !important;
      margin-left: 0;
      margin-top: 25px;
      transition: 0.3s;
      margin-top: 0;
      margin-left: 25px;

      &.is-hover:hover {
        background-color: ${({ theme }) => theme.bg.light};
        border: 1px solid ${({ theme }) => theme.font.thrid};
        color: ${({ theme }) => theme.font.third};
      }
    `}
  }
`;
