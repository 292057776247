import React, { useState } from 'react';
import { Link } from 'gatsby';

import Button from '../Button';

import { useWindowSize } from "../../hooks/resizeWindow";

import { Container } from './styles';

const Header: React.FC = () => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const [selected, setSelected] = useState('');

  const size = useWindowSize();

  const logo = 'https://zipdin-images.s3-sa-east-1.amazonaws.com/logo-renta.svg';
  const logoWhite = 'https://zipdin-images.s3-sa-east-1.amazonaws.com/logo-Renta-BW.svg';
  const logoBlack = 'https://zipdin-images.s3-sa-east-1.amazonaws.com/logo-renta-black.svg';
  const menu = 'https://zipdin-images.s3-sa-east-1.amazonaws.com/menu-24px.svg';
  const close = 'https://zipdin-images.s3-sa-east-1.amazonaws.com/close-icon.svg';

  const listItem = [
    { href: "#areas-de-atuacao", text: "Atuação" },
    { href: `#nossa-historia${size.width < 800 ? '-mobile' : ''}`, text: "História" },
    { href: "#nossos-valores", text: "Valores" },
    { href: "#quem-somos", text: "Quem somos" },
    { href: "#contato", text: "Contato" },
  ];

  const handleClick = (id) => {
    setOpenMenu(false);
    setSelected(id);
  }

  return (
    <Container openMenu={openMenu} >
      {!openMenu && <Link to="/" className="header-logo">
        <img src={logo} />
      </Link>}
      <nav className="header-nav">
        {openMenu && <div className="header-submenu-mobile">
          <Link to="/">
            <img src={logoBlack} />
          </Link>
          <button onClick={() => setOpenMenu(false)}>
            <img src={close} />
          </button>
        </div>}
        <ul>
          {listItem.map(i =>
            <li className={selected === i.href ? 'item-active' : 'none'} onClick={() => handleClick(i.href)}>
              <a href={i.href}> {i.text} </a>
            </li>
          )}
        </ul >
      </nav>
      {!openMenu && <Button
        hiddenIcon={true}
        className="button-header-mobile"
        onClick={() => setOpenMenu(true)}>
        <img className="button-img" src={menu} alt="menu" />
      </Button>}
    </Container>
  );
};

export default Header;
