import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.header`

  .header-nav {
    display: none;
  }

  img {
    height: 32px;
  }

  ${mediaQuery.desktop`
    display: flex;
    position: sticky;
    top: 0;
    opacity: 0.9;
    padding: 0px 25px;
    overflow: visible;
    z-index: 10;

    img {
      width: 140px;
      margin-top: 20px;
    }

    .header-nav {
      display: flex;
      background-color: ${({ theme }) => theme.bg.primary};
      width: 100%;
      justify-content: flex-end;

      .header-submenu-mobile {
        display: none!important;
      }
   
      li {
        color: ${({ theme }) => theme.font.white};
        font-size: 18px;
        align-self: center;
        border-top: 7px solid ${({ theme }) => theme.bg.darkGreen};
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:hover {
          border-top: 7px solid ${({ theme }) => theme.bg.lightGreen};
        }
      }

      ul {
        display: flex;
        justify-content: space-between;
        width: 35%;
      }

      img {
        width: 140px;
        margin: 30px;
      }

      item-active {
        padding-top: 0;

        div {
          width: 79px;
          height: 7px;
          background-color: ${({ theme }) => theme.bg.lightGreen};
          margin-bottom: 16px;
        }

        a {
          color: ${({ theme }) => theme.bg.lightGreen};
        }
      }

      a {
        color: ${({ theme }) => theme.font.white};
        font: 16px 'Raleway', sans-serif;
        font-weight: 600;
        transition: color 0.3s;
      }
    }
  `}

  .header-logo {
    max-width: 110px;

    ${mediaQuery.desktop`
      max-width: 139.6px;
    `}

    img {
      width: 100%;
    }
  } 

  .button-header-mobile {
    display: flex;
    border: none;
    background-color: ${({ theme }) => theme.bg.darkGreen};

    ${mediaQuery.desktop`
      display: none;
    `}
  }

    ${props => props.openMenu ? `
      height: 100vh;
      width: 100vw;
      background-color: #ffffff;
      padding: 29px 36px;
      position: sticky;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      z-index: 12;

      .header-submenu-mobile {
        display: flex;
        justify-content: space-between;
      }

      .header-nav {
        display: flex;
        position: inherit!important;
        width: 100%;
        background-color: ${({ theme }) => theme.bg.light};
        height: 11.1vh;
        top: none;
        max-width: 100%;
        padding: 0px;
        flex-direction: column;
      }

      ul {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        align-items: flex-start;
      }

      li {
        margin-bottom: 45px;
        font-size: 30px;
        text-decoration: none;
      }
      
      a {
        color: #16484A;
        font-weight: 800;
      }

      button {
        background-color: #fff;
        border: none;
      }
    `
    : `
      height: 70px;
      display: flex;
      padding: 18px 20px;
      background-color: #0E3233;
      position: sticky;
      top: 0;
      opacity: 0.9;
      justify-content: space-between;
      overflow: visible;
      z-index: 10;
    `
  }
`;
