import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.article`
  width: 300px;
  height: ${props => props.number ? '348px' : '400'};
  background: ${({ theme }) => theme.bg.green};
  border-radius: 10px;
  padding: 30px;
  margin-right: 15px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: none;

  &.itemCardTransparent {
    opacity: 0;
    width: 8%;
  }

    &:first-child {
      margin-left: 36px;
    }
    
    &:last-child {
      margin-right: 36px;
    }
  
  ${mediaQuery.desktop`
    width: 565px;
    height: ${props => props.number ? '472px' : '409px'};
    padding: 60px;
    
    &:first-child {
      margin-left: 110px;
    }

    &:first-child {
      margin-left: 110px;
    }
  
  `}

  h3 {
    font-weight: 600;
    color: ${({ theme }) => theme.font.accent};
    margin-bottom: 20px;
    font-size: 50px;
    line-height: 30px;
    
    ${mediaQuery.desktop`
      font-size: 110px;
      margin-top: -30px;
      line-height: unset;
    `}
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin: 0 0 20px 0;
    color: ${({ theme }) => theme.font.white};

    ${mediaQuery.desktop`
      font-size: 26px;
      margin-bottom: 30px;
    `}
  }

  p {
    font-size: 16px;
    font-weight: 100;
    opacity: 0.5;
    line-height: 24px;
    color: ${({ theme }) => theme.font.white};

    ${mediaQuery.desktop`
      font-size: 18px;
    `}
  }

  `;