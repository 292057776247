import React from "react";

import { Container } from "./styles";

import { JumbotronProps } from "./interface";

const JumbotronSmaller = ({
  number,
  title,
  text,
  className,
  children,
}: JumbotronProps) => {
  return (
    <Container className={className} number={number}>
      {number && <h3>{number}</h3>}
      <h4>{title}</h4>
      {!number && <p>{text}</p>}
      {children}
    </Container>
  );
};

export default JumbotronSmaller;
