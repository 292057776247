import React from 'react';

import { Container } from './styles';

import { useWindowSize } from "../../hooks/resizeWindow";

const Footer: React.FC = () => {
  const size = useWindowSize();
  const paragraph = size.width < 800 ? '12px' : '';

  const listItem = [
    { href: "#areas-de-atuacao", text: "Atuação" },
    { href: "#nossa-historia", text: "História" },
    { href: "#nossos-valores", text: "Valores" },
    { href: "#quem-somos", text: "Quem somos" },
    { href: "#contato", text: "Fale conosco" },
  ];

  const logoRentaBW = 'https://zipdin-images.s3-sa-east-1.amazonaws.com/logo-Renta-BW.svg';
  const logoAnbimaBW = 'https://zipdin-images.s3-sa-east-1.amazonaws.com/logo-anbima-bw.svg';

  const codigoDeEtica = 'https://portal-renta-docs.s3-sa-east-1.amazonaws.com/codigo-de-etica-e-conduta-2019.pdf';
  const formularioDeReferencia = 'https://portal-renta-docs.s3-sa-east-1.amazonaws.com/formulario-referencia-2020.pdf';
  const manualDeGestao = 'https://portal-renta-docs.s3-sa-east-1.amazonaws.com/manual-de-gestao-de-riscos-2019.pdf';
  const politicaDeCompra = 'https://portal-renta-docs.s3-sa-east-1.amazonaws.com/politica-de-compra-e-venda-de-valores-mobiliarios-por-colaboradores-e-em.pdf';
  const politicaDeControles = 'https://portal-renta-docs.s3-sa-east-1.amazonaws.com/politica-de-controles-internos-2019.pdf';
  const politicaDeExercicio = 'https://portal-renta-docs.s3-sa-east-1.amazonaws.com/politica-de-exercicio-de-direito-de-voto-em-assembleias-2019.pdf';
  const politicaDeGerenciamento = 'https://portal-renta-docs.s3-sa-east-1.amazonaws.com/politica-de-gerenciamento-do-risco-de-liquidez-2019.pdf';
  const politicaDePrevencao = 'https://portal-renta-docs.s3-sa-east-1.amazonaws.com/politica-de-prevencao-a-lavagem-de-dinheiro-e-do-financiamento-ao-terror.pdf';
  const politicaDeProcedimentos = 'https://portal-renta-docs.s3-sa-east-1.amazonaws.com/politica-de-procedimentos-de-prevencao-e-combate-a-corrupcao-2019.pdf';
  const politicaDeRateio = 'https://portal-renta-docs.s3-sa-east-1.amazonaws.com/politica-de-rateio-e-divisao-de-ordens-2019.pdf';

  return (
    <Container>
      <div className="links">
        <ul className="menu-list-header">
          <img src={logoRentaBW} />
          {listItem.map(i =>
            <li>
              <a href={i.href}> {i.text} </a>
            </li>
          )}
        </ul >
        <ul>
          <h5>INSTITUCIONAL</h5>
          <li><a href={codigoDeEtica} target="blank" >Código de Ética e Conduta</a></li>
          <li><a href={manualDeGestao} target="blank">Manual de Gestão de Riscos</a></li>
          <li><a href={formularioDeReferencia} target="blank" >Formulário de Referência INCVM n° 558</a></li>
          <li><a href={politicaDeControles} target="blank" >Política de Controles Internos</a></li>
          <li><a href={politicaDeRateio} target="blank">Política de Rateio e Divisões de Ordens</a></li>
        </ul>
        <ul>
          <li><a href={politicaDeExercicio} target="blank">Política de Exercício de Direito de Voto em Assembleias</a></li>
          <li><a href={politicaDePrevencao} target="blank">Política de Prevenção à Lavagem de Dinheiro</a></li>
          <li><a href={politicaDeProcedimentos} target="blank">Política de Combate à Corrupção</a></li>
          <li><a href={politicaDeCompra} target="blank">Política de Compra e Venda de Valores Mobiliários</a></li>
          <li><a href={politicaDeGerenciamento} target="blank">Política de Gerenciamento de Risco e Liquidez</a></li>
        </ul>
      </div>
      <div className="bottom">
        <p>
          © {new Date().getFullYear()} Renta Investimentos. Todos os direitos reservados.
        </p>
        <div className="anbima">
          <p>
            Somos associados à
            </p>
          <img src={logoAnbimaBW}></img>
        </div>
      </div>
    </Container >
  );
};

export default Footer;
