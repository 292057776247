import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.section`
  height: 100%;
  background-color: ${({ theme }) => theme.bg.darkGreen};
  overflow: hidden;
  z-index: 1;

  #welcome {
    width: inherit;
    display: flex;
    flex-direction: column;
    margin-top: -70px;
    padding: 120px 9.7vw;
    height: 100vh;
    justify-content: unset;
    align-items: center;
    
    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    
    .rectangle {
      background-color: ${({ theme }) => theme.bg.lightGreen};
      width: 40px;
      height: 7px;
      margin-bottom: 2.2vh;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: unset;
      align-items: center;
    }
    
    h1 {
      font-size: 30px;
      text-align: left;
      width: unset;
      color: ${({ theme }) => theme.font.white};
    }
    
    img {
      max-width: 44.2vw;
      margin-top: 48px;
    }
    
    ${mediaQuery.desktop`
      flex-direction: column;
      justify-content: space-between;
      padding: 9.3vh 110px;

      div {
        display: flex;
        flex-direction: row;
        justify-content: unset;
        align-items: center;
        width: 100%;
      }
      
      h1 {
        font-size: 60px;
        width: 45.6vw;
      }
      
      img {
        max-width: 21.1vw;
      }
    `}
  }

  #areas-de-atuacao {

  }

  #nossa-historia {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;

     img {
      margin-left: -30px;
      max-width: 290px;
      max-height: 358.6px;
    }
    
    ${mediaQuery.desktop`
      margin-top: 200px;
      
      img {
        margin-left: -50px;
        margin-right: 100px;
        max-width: 567.9px;
        max-height: 708.6px;
      }

      section {
        max-width: 570px;
      }
    `}
  }

  #nossos-valores {

  }

  #quem-somos {
    display: flex;
    flex-direction: column;
    margin: 0;
    max-width: 100%;
    margin-bottom: 70px;
    color: ${({ theme }) => theme.font.white};

    ${mediaQuery.desktop`
      margin: 150px 0 0 0;
      padding-bottom: 100px;
    `}

    .text-block {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 40px 0 36px;
      
      ${mediaQuery.desktop`
        margin: 0px 240px 0px 110px;
        padding: 0;
      `}
    }

    .item {
      display: flex;
      flex-direction: column;
      width: 474px;

      &:first-child {
        margin-bottom: 50px;
      }
    }

    h5 {
      font-size: 26px;
    }
    
    p {
      margin-top: 30px;
      font-size: 16px;
      padding: 0;
      opacity: 0.5;
      letter-spacing: 0.8;
      line-height: 24px;

      ${mediaQuery.desktop`
        margin-top: 30px;
        font-size: 20px;
        padding: 0;
        opacity: 0.5;
        letter-spacing: 0.18px;
        line-height: 30px;
      `}
    }
  }

  #contato {
    background-color: ${({ theme }) => theme.bg.light};
    color: ${({ theme }) => theme.font.primary};
    padding: 0 40px 40px 36px;
    margin: 0;
    box-sizing: unset;
    height: auto;
    
    ${mediaQuery.desktop`
      padding: 70px 238px 70px 140px;
    `}

    h2 {
      color: ${({ theme }) => theme.font.dark};
    }

    h6 {
      font-size: 20px;
      margin-bottom: 5px;
    }

    a {
      color: ${({ theme }) => theme.font.primary};
      font-size: 16px;
      margin-bottom: 40px;
      max-width: 376px;
      opacity: 0.7;
    }

    section {
      margin-left: 0;
    }

    span {
      font-size: 14px;
      text-align: left;
      color: ${({ theme }) => theme.font.primary};
    }

    button {
      width: 100%;
      height: 61px;
      font-size: 18px;
      border-color: ${({ theme }) => theme.bg.green};
      color: ${({ theme }) => theme.font.light};
      background-color: ${({ theme }) => theme.bg.green};

      ${mediaQuery.desktop`
        width: 126px;
        border-width: 2px;
        font-size: 18px;
        
        &:hover {
          color: ${({ theme }) => theme.font.white};
          background-color: ${({ theme }) => theme.font.primary};
        }
      `}
    }

    .group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap-reverse;

      ${mediaQuery.desktop`
        width: 80vw;
        padding-right: 100px;
      `}
    }

    .text {
      display: flex;
      flex-direction: column;

      &:first-child {
        margin-top: 50px;
        
        ${mediaQuery.desktop`
          margin-top: 0px;
        `}
      }
    }

    .form {
      width: 100%;
      
      ${mediaQuery.desktop`
        width: 35vw;
      `}
    }

    .input-group {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      ${mediaQuery.desktop`
        flex-direction: row;
      `}

    }

    .input-block {

      ${mediaQuery.desktop`
        width: 49%;
      `}

      &:first-child {
        margin-bottom: 30px;

        ${mediaQuery.desktop`
          margin-bottom: 0px;
        `}
      }
    }

    .recaptcha {
      margin: 40px 15px;
      align-self: center;
    }

    .error {
      color: ${({ theme }) => theme.font.error};
    }
  }
`;
