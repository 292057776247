import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 24px;

  display: flex;
  flex-direction: column;
  outline: none;

  label {
    color: ${({ theme }) => theme.font.primary};
    font-size: 14px;
    font-weight: 200;
    letter-spacing: 0.26px;
  }

  input {
    height: 23px;
    color: ${({ theme }) => theme.font.second};
    border: 1px solid transparent;
    display: block;
    font-weight: 600;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  input:focus {
    outline: none;
  }

  .container-error {
    padding-top: 7px;
    border-top: 2px solid #0002;

    span {
      color: ${({ theme }) => theme.font.error};
      letter-spacing: 0.26px;
      font-size: 13px;
      font-weight: 400;
      font-family: 'Raleway', sans-serif;
    }
  }
`;
