import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.section`
  padding-top: 80px;
  justify-content: flex-start;
  margin: 0 40px 41.2px 36px;
  width: 80vw;
  
  ${mediaQuery.desktop`
    padding-top: 80px;
    margin: 0 110px 50px 110px;
  `}
  
  .rectangle {
    background-color: ${({ theme }) => theme.bg.lightGreen};
    margin-bottom: 2.2vh;
    width: 25px;
    height: 5px;

    ${mediaQuery.desktop`
      width: 40px;
      height: 7px;
    `}
  }

  h2 {
    font-size: 30px;
    font-family: 'Raleway', sans-serif;
    color: ${({ theme }) => theme.font.white};
    text-align: left;
    font-weight: 800;
    margin-bottom: ${props => props.text ? '50px' : ''};

    ${mediaQuery.desktop`
      font-size: 40px;
    `}
  }

  p {
    max-width: 570px;
    font-size: 16px;
    font-family: 'Raleway', sans-serif;
    color: ${({ theme }) => theme.font.white};
    text-align: left;
    letter-spacing: 0.8px;
    margin: 0 0 50px 0;
    opacity: 0.6;
    line-height: 24px;
    
    ${mediaQuery.desktop`
      max-width: 570px;
      font-size: 18px;
      padding: 0;
      opacity: 0.5;
      letter-spacing: 0.18px;
      margin-bottom: 50px;
      line-height: 28px;
    `}
  }
`;
