import React from "react";
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

import Layout from "../components/Layout";
import TitleSection from "../components/TitleSection";
import Button from "../components/Button";
import Slider from "../components/Slider";
import Message from "../components/Message";
import Input from '../components/Input';
import Textarea from '../components/Textarea';

import { Container } from "../components/HomeStyle/styles";

import contentHome from "../content/pages/Home";

import handleValidate from '../helpers/validations/formValidations';
import templateEmail from '../helpers/templates/email';

const Home: React.FC = () => {
  let size;
  if (typeof window != "undefined") size = window.innerWidth;
  const recaptchaRef = React.useRef();

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [textarea, setTextarea] = React.useState('');
  const [recaptcha, setRecaptcha] = React.useState('');
  const [showMessage, setShowMessage] = React.useState(false);
  const [validateFields, setValidateFields] = React.useState(false);
  const [positionTransition, setPositionTransition] = React.useState(0);

  const coins = 'https://zipdin-images.s3-sa-east-1.amazonaws.com/coins-shadow.png';
  const renta = 'https://zipdin-images.s3-sa-east-1.amazonaws.com/renta-symbol.svg';
  const rentaMobile = 'https://zipdin-images.s3-sa-east-1.amazonaws.com/renta-symbol-mobile.svg';
  const arrow = 'https://zipdin-images.s3-sa-east-1.amazonaws.com/bottom-arrow.svg';
  const check = 'https://zipdin-images.s3-sa-east-1.amazonaws.com/ic_check.svg';

  React.useEffect(() => {
    document.querySelector("body").scrollTo(0, 0);
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    setValidateFields(true);

    if (!!name && !!email && !!recaptcha) {

      const emailFormated = templateEmail({ name, email, textarea })

      await axios.post("https://app-consig.zipdin.com.br/app-consig/services/email/send", emailFormated);

      setShowMessage(true);
      setValidateFields(false);

      setName('');
      setEmail('');
      setTextarea('');

      return;
    }
  };

  return (
    <>
      <Layout >
        <Container id="app" position={positionTransition}>
          <section id="welcome">
            <div>
              <div className="title">
                <div className="rectangle"></div>
                <h1>Atuação estratégica na gestão de Fundos de Investimentos em Direitos Creditórios</h1>
              </div>
              <img src={coins} />
            </div>
            <img src={arrow} className="arrow" />
          </section>
          <section id="areas-de-atuacao">
            <TitleSection
              title="Áreas de Atuação"
            />
            <Slider items={contentHome.cardsAreasDeAtuacao} arrowColor="#fff" classContainer={'atuacao'} id={0} margin={size > 800 ? 250 : 70} />
          </section>
          <section id="nossa-historia">
            <img src={size < 800 ? rentaMobile : renta} alt="renta-symbol" />
            <section id="nossa-historia-mobile">
              <TitleSection
                title="Nossa história"
                text="A Renta Gestão de Recursos Ltda. (“Renta Investimentos”), foi constituída em 2005 e desde então vem se especializando na estruturação e gestão de fundos Estruturados, em especial os Fundos de Investimentos em Direitos Creditórios, tendo sido responsável pela estruturação de um dos primeiros fundos do mercado. Ao longo do tempo adquiriu experiência em diversos segmentos tais como: multicedente / multissacado, Crédito Consignado Público e Privado, Cartões de Crédito, Crédito Pessoal e Crédito Direto ao Consumidor."
                text2="Com mais de R$200 milhões sob gestão, a Renta Investimentos busca estruturar fundos mais eficientes para seus clientes e ao mesmo tempo oferecer excelentes oportunidades de investimento ao mercado, atuando sempre com transparência e dando grande importância a preservação de capital."
              />
            </section>
          </section>
          <section id="nossos-valores"
          >
            <TitleSection
              title="Nossos valores"
            />
            <Slider items={contentHome.cardsNossosValores} arrowColor="#fff" classContainer={'valores'} id={1} margin={size > 800 ? 250 : 70} />
          </section>
          <section id="quem-somos"
          >
            <TitleSection
              title="Quem somos"
            />
            <div className="text-block">
              {contentHome.quemSomos.map(i =>
                <div className="item">
                  <h5>{i.title}</h5>
                  <h5>{i.title2}</h5>
                  <p>{i.text}</p>
                  <p>{i.text2}</p>
                </div>
              )}
            </div>
          </section>
          <section id="contato"
          >
            <TitleSection
              title="Fale conosco"
            />
            <div className="group">
              <div className="text">
                <h6>Endereço</h6>
                <a>Rua Guilhermina Guinle, 272 - Sala 801 - Botafogo - CEP: 22270-060 - Rio de Janeiro/RJ</a>
                <h6>E-mail</h6>
                <a href="mailto: operacoes@rentainvestimentos.com.br">operacoes@rentainvestimentos.com.br</a>
                <h6>Telefone</h6>
                <a href="tel: +55 21 2532-1268">+55 21 2532-1268</a>
              </div>
              <div className="form">
                <form onSubmit={handleSubmit}>
                  <div className="input-group">
                    <div className="input-block">
                      <Input
                        name="name"
                        type="text"
                        label="Nome do responsável"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        error={validateFields && handleValidate(!name)}
                        errorMsg="Precisamos dessa informação."
                      />
                    </div>
                    <div className="input-block">
                      <Input
                        name="email"
                        type="email"
                        label="E-mail do responsável"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        error={validateFields && handleValidate(!email)}
                        errorMsg="Precisamos de um e-mail válido."
                      />
                    </div>
                  </div>
                  <Textarea
                    name="textarea"
                    label="Mensagem (opcional)"
                    value={textarea}
                    onChange={e => setTextarea(e.target.value)}
                  />
                  <div className="recaptcha">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LdXAW0aAAAAABulcoAIl5tsIIKAYsKyj6da5mto"
                      onChange={e => setRecaptcha(e)}
                    />
                  </div>
                  <Button
                    type="submit"
                    hiddenIcon={true}
                  >Enviar</Button>
                </form>
              </div>
            </div>
          </section>
        </Container>
      </Layout>
      {showMessage && <Message
        onClick={() => setShowMessage(false)}
        state={showMessage}
        icon={check}
        text="Sua mensagem foi enviada com sucesso! Por favor, aguarde o nosso contato."
        buttonText="OK"
      />}
    </>
  );
};

export default Home;
