import React, { InputHTMLAttributes, useRef } from 'react';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  type: string;
  label: string;
  error: boolean;
  errorMsg: string;
  value: string;
}

const Input: React.FC<InputProps> = ({
  name,
  type,
  label,
  value,
  error,
  errorMsg,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Container isErrored={error}>
      <label htmlFor={name}>{label}</label>
      <input name={name} ref={inputRef} type={type} value={value} {...rest} />
      <div className="container-error">{error && <span className="error">{errorMsg}</span>}</div>
    </Container>
  );
};

export default Input;
