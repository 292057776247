export default {
  cardsAreasDeAtuacao: [
    {
      content: [
        {
          id: 0,
          title: "FIPs - Fundos de Investimentos em Participações",
          text: "Neste segmento a Renta Investimentos atua na gestão de FIPs exclusivos visando otimizar a estrutura de investimentos em empresas nas quais os seus clientes tenham participação relevante, bem como auxiliá-los na gestão dos mesmos.",
        },
        {
          id: 1,
          title: "FICs - Fundos de Investimentos em Cotas",
          text: "Nesta área selecionamos alguns dos melhores fundos do mercado para compor a carteira dos nossos fundos de fundos, que apresentam diferentes características para atender aos perfis de cada grupo de clientes, oferecendo assim oportunidades de diversificação e eficiência em produtos únicos.",
        },
        {
          id: 2,
          title: "FIDCS - Fundos de Investimentos em Direitos Creditórios",
          text: "A Renta Investimentos estrutura e faz a gestão de FIDCS tanto para otimizar e viabilizar as necessidades de funding de seus clientes como para oferecer aos investidores excelentes opções de investimento em renda fixa com altos retornos e forte segurança.",
        },
      ],
    },
  ],
  cardsNossosValores: [
    {
      content: [
        {
          number: 1,
          title: "Ética e transparência na condução de seus negócios",
        },
        {
          number: 2,
          title: "Qualidade e eficiência em seus serviços",
        },
        {
          number: 3,
          title: "Respeito como prática em seus relacionamentos internos e externos, com a permanente busca por melhoria na qualidade de vida de todos com os quais se relaciona",
        },
        {
          number: 4,
          title: "Confidencialidade no trato de informações, dos mercados onde atua e dos respectivos participantes",
        },
        {
          number: 5,
          title: "Responsabilidade e preservação e de seus clientes e parceiros",
        },
        {
          number: 6,
          title: "Honestidade no gerenciamento de seu patrimônio e de seus clientes",
        },
      ],
    },
  ],
  quemSomos: [
    {
      title: "Francisco",
      title2: "da Costa Carvalho",
      text: "Sócio fundador da Renta Investimentos e Diretor responsável pela Administração de Carteiras (Gestão de Recursos de Terceiros). Com mais de 20 anos de experiência no mercado de capitais brasileiro, iniciou sua carreira como Analista de Corporate Finance na Capitaltec S/A (Banco Brascan) e galgou a posição de Vice-Presidente Administrativo-Financeiro do Grupo Lecca (Lecca Financeira, Lecca DTVM e Lecca Gestão de Recursos).",
      text2: "Em sua formação acadêmica, além de ser Bacharel em Economia pela Pontifícia Universidade Católica (PUC-RJ), obteve MBA com ênfase em Finanças pelo COPPEAD e especialização em Finanças pela University of Califórnia – Berkeley.",
    },
    {
      title: "Carlos",
      title2: "de Andrade Luz",
      text: "Sócio fundador da Renta Investimentos e Diretor responsável pelas áreas de Controles Internos e Riscos. Em seus mais de 35 anos de experiência em diversas áreas do mercado financeiro, foi Gerente de Operações da Financiadora Mesbla – CFI S/A e do Banco Prosper S/A, onde foi responsável por desenvolver e implantar os produtos CDC-Lojista e Cartão de Crédito. Ingressou no Grupo Lecca em 1999, onde foi Superintendente de Crédito e Cobrança e Diretor de Risco e Planejamento no período de 2005 a 2014.",
      text2: "Possui MBA em Gestão Financeira com ênfase em Mercado de Capitais pela FGV/RJ e é Bacharel em Economia pela Universidade Cândido Mendes.",
    },
  ],
};
