type templateEmailProps = {
  name: string;
  email: string;
  textarea: string;
}

export default function templateEmail({ name, email, textarea }: templateEmailProps) {
  const mensage = {
    assunto: "Renta Investimentos",
    mensagem: "Olá, você recebeu uma mensagem a partir do site da Renta Investimentos:<br><br>:" +
      "<br>Nome: " + name +
      "<br>Endereço de e-mail: " + email +
      "<br>Mensagem: " + textarea +
      "<br>",
    email: "operacoes@rentainvestimentos.com.br"
  };

  return mensage;
}