import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.footer`
  background-color: ${({ theme }) => theme.bg.darkerGreen};
  color: ${({ theme }) => theme.font.white};
  overflow: hidden;
  padding: 80px 10vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  a {
    color: ${({ theme }) => theme.font.white};
    font-size: 16px;
    opacity: 0.6;
    
    ${mediaQuery.desktop`
      font-size: 13px;
      text-decoration-line: underline;
    `}
  }

  img {
    color: ${({ theme }) => theme.font.white};
    ${mediaQuery.desktop`
      margin-bottom: 30px;
    `}
  }

  ul {
    display: block;
  }

  li {
    margin-bottom: 10px;
  }

  h5 {
    font-size: 16px;
    margin-bottom: 25px;
    margin-top: 50px;
    
    ${mediaQuery.desktop`
      margin-bottom: 30px;
      margin-top: 0;
    `}
  }

  .links {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;

    img {
      margin-bottom: 20px;
    }
  }

  .bottom {
    margin-top: 8.4vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    ${mediaQuery.desktop`
      justify-content: space-between;
    `}

    p {
      margin-right: 5px;
      margin-bottom: 50px;

      ${mediaQuery.desktop`
        margin-bottom: 0;
        align-self: center;
      `}
    }
  }

  .anbima {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    align-items: center;

    p {
      margin-right: 5px;
      margin-bottom: 10px;
    }
    
    ${mediaQuery.desktop`
      p {
        margin-bottom: 0;
      }
      img {
        margin-top: 10px;
      }

      flex-direction: row;
    `}

  }
`;
